export default function Game() {
    return (
        <div style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
            <iframe
                title="Cocos Game"
                src={`${process.env.PUBLIC_URL}/web-mobile/index.html`}
                width="100%"
                height="100%"
                style={{ border: 'none' }}
            />
        </div>
    );
}
