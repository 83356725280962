import { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import "./ProgressView.css";

import Timer from "../../Timer";

import { claimCoins } from "../../../data/api";
import { userInfoOptions } from "../../../data/queryOptions";

import { claim_pause } from "../../../config/config";

const telegramId = window.Telegram.WebApp.initDataUnsafe?.user?.id || -1;
const claimPause = parseInt(claim_pause!);

function ProgressView() {
  const queryClient = useQueryClient();
  const {
    data: userInfo,
    isPending,
    isError,
  } = useQuery(userInfoOptions(telegramId));

  const [nextClaim, setNextClaim] = useState<number | null>(null);
  const [isClaimReady, setIsClaimReady] = useState(false);

  const putClaim = useMutation({
    mutationFn: () => claimCoins(telegramId),
    onSuccess: async (value) => {
      if (value === undefined) {
        throw new Error("undefined result");
      }

      if (value > claimPause) {
        queryClient.refetchQueries(userInfoOptions(telegramId));
      }
    },
    onError: (error) => {
      console.error(`claimCoins error: ${error}`);
    },
  });

  useEffect(() => {
    if (!userInfo) return;

    const nextClaim = Date.parse(userInfo.lastClaim) + claimPause;
    setNextClaim(nextClaim);
    if (nextClaim < Date.now()) setIsClaimReady(true);
    else setIsClaimReady(false);
  }, [userInfo]);

  useEffect(() => {
    if (userInfo && !userInfo.email) globalThis.location.href = "/email";
  }, [userInfo]);

  // TODO: refactor | add sockets
  function tryToClaim() {
    if (telegramId !== undefined) {
      if (isClaimReady) {
        putClaim.mutate();
      }
    }
  }

  if (isPending) {
    return <>Loading...</>;
  }

  if (isError) {
    return <>Something went wrong...</>;
  }

  return (
    <div>
      <header className="vertical-center">
        <h1>your progress</h1>
      </header>

      <div className="horizontal-center">
        <img className="top" src="/images/top-split-line.png" alt="" />
      </div>

      <div className="wallet-holder">
        <div className="horizontal-center">
          <img className="wallet" src="/images/wallet.png" alt="" />
        </div>

        <div className="horizontal-center">
          <h1>
            <span className="wallet wallet-value">
              {userInfo!.coins.toLocaleString()}
            </span>{" "}
            <span className="wallet"> HAT</span>
          </h1>
        </div>

        <div className="horizontal-center">
          <img className="center" src="/images/little-split-line.png" alt="" />
        </div>
      </div>

      <div className="horizontal-center">
        <button
          id="claim-button"
          className={isClaimReady ? "claim claim-ready" : "claim claim-waiting"}
          onClick={tryToClaim}
        >
          {isClaimReady ? (
            <p className="claim-text">CLAIM AVAILABLE</p>
          ) : (
            <p className="claim-text">
              <span>CLAIM IN</span>{" "}
              {nextClaim && (
                <Timer
                  time={new Date(nextClaim)}
                  showUnits={false}
                  expirationMessage=""
                  expireCallback={() => setIsClaimReady(true)}
                />
              )}
              {/* <img className="claim-icon" src="/images/icon-time.png" alt="" /> */}
            </p>
          )}
        </button>
      </div>
      <h1
        className="horizontal-center wallet-holder play-button"
        onClick={() => (globalThis.location.href = "/game")}
      >
        <span className="wallet-value">PLAY</span>
      </h1>
    </div>
  );
}

export default ProgressView;
