import { useState } from 'react';

import './MainPage.css';

import FrensView from './views/FrensView';
import LeaderboardView from './views/LeaderboardView';
import ProgressView from './views/ProgressView';
import QuestsView from './views/QuestsView';

import NavMenu from '../NavMenu';
import Popup from '../Popup';

import { NavMenuItem } from '../../data/types';

import { ReactComponent as HomeIcon } from '../../images/home.svg'
import { ReactComponent as TaskIcon } from '../../images/task.svg'
import { ReactComponent as FrensIcon } from '../../images/frens.svg'
import { ReactComponent as MarketIcon } from '../../images/market.svg'
import { ReactComponent as LeaderIcon } from '../../images/leader.svg'
import MarketView from './views/MarketView';


function MainPage() {
  const [selectedMenuItemId, setSelectedMenuItemId] = useState<number>(1);

  const menuItems: NavMenuItem[] = [
    {
      id: 1,
      name: 'Points',
      icon: <HomeIcon />,
      callback: () => setSelectedMenuItemId(1),
    },
    {
      id: 2,
      name: 'Tasks',
      icon: <TaskIcon />,
      callback: () => setSelectedMenuItemId(2),
    },
    {
      id: 3,
      name: 'Frens',
      icon: <FrensIcon />,
      callback: () => setSelectedMenuItemId(3),
    },
    {
      id: 4,
      name: 'Market',
      icon: <MarketIcon />,
      callback: () => setSelectedMenuItemId(4),
    },
    {
      id: 5,
      name: 'LeaderBoaed',
      icon: <LeaderIcon />,
      callback: () => setSelectedMenuItemId(5),
    },
  ];



  return (
    <div className="page-root">
      <Popup />

      {selectedMenuItemId === 1 && <ProgressView />}
      {selectedMenuItemId === 2 && <QuestsView />}
      {selectedMenuItemId === 3 && <FrensView />}
      {selectedMenuItemId === 4 && <MarketView />}
      {selectedMenuItemId === 5 && <LeaderboardView/> }

      <NavMenu items={menuItems} selectedItemId={selectedMenuItemId} setSelectedMenuItemId={setSelectedMenuItemId}/>
    </div>
  );
}

export default MainPage;
