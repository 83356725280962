import { useCallback, useState } from 'react';
import { applyEmail } from '../../data/api';
import './EmailPage.css';


const telegramId = window.Telegram.WebApp.initDataUnsafe?.user?.id || -1;

function EmailPage() {
    const [email, setEmail] = useState('');
    const [disabled, setDisabled] = useState(false);

    const applyEmailFn = useCallback(async () => {
        setDisabled(true);
        await applyEmail(telegramId, email);
        globalThis.location.href = '/';
    }, [email]);

    return (
        <div className="email-page">
            <header className="vertical-center">
                <h1>WELCOME TO HATTORI</h1>
            </header>
            <div className="email-page__image" />
            <div className="email-page__input-group">
                <input className="email-page__input" placeholder="Enter your email" onChange={e => setEmail(e.currentTarget.value)} />
                <div className="email-page__note">
                    <div>Don't miss out on important updates</div>
                    <div>And quests! </div>
                    <div>Add your email so that we can notify you.</div>
                </div>
            </div>
            <div className="email-page__button" onClick={() => disabled ? null : applyEmailFn()}>JOIN THE WHITELIST</div>
        </div>
    );
}

export default EmailPage;
