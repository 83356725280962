import { useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useQuery } from '@tanstack/react-query';
import { LeaguesI, UserData } from '../../../data/types';
import FrenInfo from '../../FrenInfo';
import { Leaders, League, Position, Squads, joinSquad as joinSquadAPI } from '../../../data/api';
import { leadersOptions, positionOptions, squadsOptions, userInfoOptions } from '../../../data/queryOptions';
import 'swiper/css/navigation';
import 'swiper/css';
import './LeaderboardView.css';


enum Tab {
    SOLO = 'SOLO',
    SQUAD = 'SQUAD',
}

const leagues: LeaguesI[] = [
    {
        id: '1',
        title: League.IRON,
        description: '50% of the best shuriken masters',
        image: '/images/iron.png',
    },
    {
        id: '2',
        title: League.SILVER,
        description: '50% of the best shuriken masters',
        image: './images/silver.png',
    },
    {
        id: '3',
        title: League.PLATINUM,
        description: '15% of the best shuriken masters',
        image: '/images/platinum.png',
    },
    {
        id: '4',
        title: League.DIAMOND,
        description: '5% of the best shuriken masters',
        image: '/images/diamond.png',
    },
    {
        id: '5',
        title: League.GRANDMASTER,
        description: '1% of the best shuriken masters',
        image: './images/grandmaster.png',
    },

];

const telegramId = window.Telegram.WebApp.initDataUnsafe?.user?.id || -1;

function LeaderboardViewLoader() {
    const { data: leaders, isPending: isPendingLeaders, isError: isErrorLeaders } = useQuery(leadersOptions());
    const { data: squads, isPending: isPendingSquads, isError: isErrorSquads } = useQuery(squadsOptions());
    const { data: user, isPending: isPendingUserSquads, isError: isErrorUser, refetch: refetchUser } = useQuery(userInfoOptions(telegramId));
    const { data: position, isPending: isPendingPosition, isError: isErrorPosition } = useQuery(positionOptions(telegramId));

    const refetch = useCallback(() => refetchUser(), [refetchUser]);

    if (isPendingLeaders || isPendingSquads || isPendingUserSquads || isPendingPosition) return (<>Loading...</>);

    if (isErrorLeaders || isErrorSquads || isErrorUser || isErrorPosition) return (<>Something went wrong.</>);

    return (<LeaderboardView leaders={leaders} squads={squads} user={user} position={position} refetch={refetch} />);
}

function LeaderboardView({ leaders, squads, user, position, refetch }: { leaders: Leaders, squads: Squads, user: UserData, position: Position, refetch: () => Promise<unknown> }) {
    const [selectedSquad, setSelectedSquad] = useState<Squads[League][number] | null>(null);
    const [league, setLeague] = useState(League.IRON);
    const [tab, setTab] = useState(Tab.SOLO);

    const swiperRef = useRef<SwiperRef>(null);

    const joinSquad = useCallback(async (telegramId: number, squadId: string) => {
        await joinSquadAPI(telegramId, squadId);
        setSelectedSquad(null);
        await refetch();
    }, [refetch]);

    return (
        <div className="leaderboard">
            {selectedSquad && <SquadPopup user={user} squad={selectedSquad} league={league} onClose={() => setSelectedSquad(null)} joinSquad={joinSquad} />}
            <header className="leaderboard_header vertical-center">
                <div className="leaderboard-switcher">
                    <div className={classNames({ switcher__item: true, 'switcher__item-active': tab === Tab.SOLO })} onClick={() => setTab(Tab.SOLO)}>
                        <p>SOLO</p>
                    </div>
                    <div className={classNames({ switcher__item: true, 'switcher__item-active': tab === Tab.SQUAD })} onClick={() => setTab(Tab.SQUAD)}>
                        <p>SQUAD</p>
                    </div>
                </div>
            </header >
            <div className="league_swiper-wrapper">
                <LeagueSwiper swiperRef={swiperRef} setActiveLeague={setLeague} />
            </div>
            {tab === Tab.SOLO && <SoloTab leaders={leaders} position={position} league={league} user={user} />}
            {tab === Tab.SQUAD && <SquadTab squads={squads} user={user} league={league} selectSquad={setSelectedSquad} />}
        </div >
    );
}

export default LeaderboardViewLoader;

function SoloTab({ leaders, position, league, user }: { leaders: Leaders, position: Position, league: League, user: UserData }) {
    const member = useMemo(() => position.league === league, [position, league]);

    return (
        <div className="league_leaders" key={league}>
            {member &&
                <div className="league_leaders-user">
                    <div className="league_user">
                        <div className="league_user-cover" />
                        <FrenInfo member={true} position={position.position} name={user.name} hatCoins={user.totalBalance} />
                    </div>
                    <div className="league_leaders-user-separator" />
                </div>
            }
            {leaders[league].leaders.map((leader, index) =>
                <FrenInfo position={String(index + 1)} name={leader.name} hatCoins={leader.totalBalance} key={index} />
            )}
        </div>
    );
}

function SquadTab({ squads, user, league, selectSquad }: { squads: Squads, user: UserData, league: League, selectSquad: (squad: Squads[League][number]) => void }) {
    const squadOfUserInThisLeague = useMemo(() => {
        const squadOfUser = Object.values(squads).flatMap(squads => squads).find(squad => squad._id === user.squad);
        if (!squadOfUser) return null;
        return squadOfUser.league === league ? squadOfUser : null;
    }, [squads, user, league]);

    return (
        <>
            <div className="squad_leaders">
                {squads[league].map((squad, index) =>
                    <div key={squad._id} onClick={() => selectSquad(squad)} className="fren_info_wrapper">
                        <FrenInfo isSquad={true} position={squad.position} name={squad.name} hatCoins={squad.totalBalance} key={index} />
                    </div>
                )}
            </div>
            {
                squadOfUserInThisLeague &&
                <div className="fren_info_wrapper" onClick={() => selectSquad(squadOfUserInThisLeague)}>
                    <FrenInfo isSquad={true} position={squadOfUserInThisLeague.position} name={squadOfUserInThisLeague.name} hatCoins={squadOfUserInThisLeague.totalBalance} league={league} />
                </div>
            }
        </>
    );
}

function LeagueSwiper({ swiperRef, setActiveLeague }: { swiperRef: React.RefObject<SwiperRef>, setActiveLeague(value: League): void }) {
    const handleSlideChange = (swiper: any) => {
        const activeIndex = swiper.activeIndex;
        setActiveLeague(leagues[activeIndex].title);
    };

    return (
        <Swiper ref={swiperRef} navigation={true} modules={[Navigation]} className="league_swiper" onSlideChange={handleSlideChange}>
            {leagues.map((item, index) => (
                <SwiperSlide key={item.id}><LeagueSlide item={item} number={index + 1} /></SwiperSlide>
            ))}
        </Swiper>
    );
}

function LeagueSlide({ item, number }: { item: LeaguesI, number: number }) {
    return (
        <div className="league_item">
            <div className="league_item-image">
                <p>{number}</p>
                <img src={item.image} alt="" />
            </div>
            <p className="league_item-title">{item.title}</p>
            <p className="league_item-description">{item.description}</p>
        </div>
    );
}

function SquadPopup({ squad, user, league, onClose, joinSquad }: { squad: Squads[League][number], user: UserData, league: League, onClose: (value: boolean) => void, joinSquad: (telegramId: number, squadId: string) => Promise<void> }) {
    const leadersRef = useRef<HTMLDivElement>(null);

    const isUserSquad = useMemo(() => user.squad === squad._id, [user, squad]);
    const position = useMemo(() => {
        if (!isUserSquad) return '';
        const index = squad.topPlayers.findIndex(player => player.name === user.name);
        if (~index) return String(index);
        else return '100+';
    }, [isUserSquad, user, squad]);

    return (
        <div className="squad_popup-wrapper open">
            <div className="squad_popup">
                <div className="squad_popup-cover" />
                <header className="squad_popup-header">
                    <div className="squad_popup-back" onClick={() => onClose(false)} />
                </header>
                <div className="squad_popup-info">
                    <img src={require('../../../images/squad2.png')} className="squad_popup-info_avatar" alt="" />
                    <p className="squad_popup-info_name">{squad.name}</p>
                    <div className="squad_popup-info_place">
                        <img
                            className="info_place-league"
                            src={`/images/${league.toLowerCase()}.png`}
                            alt=""
                        />
                        <p>{squad.position} place</p>
                    </div>
                    <div className="squad_popup-info_separator" />
                    <div className="squad_popup-info_coins">
                        <div className="popup-info_coins-item">
                            <div className="popup-info_fair-coin" />
                            <p id="red">{squad.totalBalance}</p>
                        </div>
                        <div className="popup-info_coins-item">
                            <div className="popup-info_user-coin" />
                            <p>{squad.count}</p>
                        </div>
                    </div>
                </div>
                <div className="squad_popup-items" ref={leadersRef}>
                    <div className="squad_popup-buttons">
                        <button className="chat-button">
                            <p>SQUAD CHAT</p>
                        </button>
                        {!isUserSquad &&
                            <button className="join-button" onClick={() => joinSquad(user.telegramId, squad._id)}>
                                <p>join in</p>
                            </button>}
                    </div>
                    {isUserSquad &&
                        <div className="league_leaders-user">
                            <div className="league_user">
                                <div className="league_user-cover" />
                                <FrenInfo member={true} position={position} name={user.name} hatCoins={user.totalBalance} />
                            </div>
                            <div className="league_leaders-user-separator" />
                        </div>
                    }
                    {squad.topPlayers.map((player, index) =>
                        <FrenInfo position={String(index + 1)} name={player.name} hatCoins={player.totalBalance} key={index} />
                    )}
                </div>
            </div>
        </div>
    );
}