import { League } from '../data/api';
import './FrenInfo.css';


export interface FrenInfoProps {
  position: string;
  name: string;
  hatCoins: number;
  league?: League;
}

function FrenInfo({ position, name, hatCoins, member = false, isSquad = false, league }: FrenInfoProps & { member?: boolean; isSquad?: boolean }) {
  const getIconPath = () => {
    const value = Math.floor(Math.random() * 5) + 1;
    if (isSquad) return `/images/squad${value}.png`;
    return `/images/avatar-${value}.png`;
  };

  return (
    <div className="fren-border" style={{ overflowX: (isSquad && member) ? 'visible' : 'hidden' }}>
      {(isSquad && member && league) ?
        <>
          <div className='fren-border-user_league-wrapper' />
          <div className='vertical-center'>
            <img
              className="league-avatar"
              src={`/images/${league}.png`}
              alt=""
            />
          </div>
        </>
        :
        <>
          {(!member && Number(position) < 4) && <div className="fren-top-border"></div>}
          {(!member && Number(position) > 3) && (
            <img
              className="fren-pos-icon"
              src="/images/pos-mark.png"
              alt=""
            />
          )}
          <div className="fren-position-holder vertical-center">
            <span className="fren">{position}</span>
          </div>
        </>
      }
      <div className="fren-avatar-holder vertical-center">
        <img
          className="fren-avatar"
          src={getIconPath()}
          alt=""
        />
      </div>
      <div className="fren-name-holder vertical-center">
        <span className="fren fren-name">{name || 'Ninja'}</span>
        <div className="fren-coins-holder">
          <div className='coin-icon' />
          <span className="coin">{hatCoins}</span>
        </div>
      </div>
      {(isSquad && member && league) &&
        <div className='squad-arrow_wrapper' >
          <div className='squad-arrow_right' />
        </div>}
    </div>
  );
}

export default FrenInfo;
