import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './WelcomePage.css';


function WelcomePage() {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(function () {
            navigate("/");
        }, 3000);
    }, [navigate]);

    return (
        <div className="vertical-center">
            <header className="vertical-center">
                <h1>WELCOME TO HATTORI</h1>
            </header>
            <div>
                <img className="preview" src="/images/preview.png" alt="Hattori" />
            </div>
            <div className="vertical-center description-holder">
                <article className="top description">
                    Next-Gen mobile pvp action game<br />
                    with integrated web3 and AI features.<br />
                    Craft your own shurikens,<br />
                    throw it to your friends and become<br />
                    a rich Ninja in the real world.<br />
                </article>

                <img className="mask" src="/images/mask-group.png" alt="Hattori" />

                <article className="bottom description">
                    Collect HAT Points To Be Able<br />
                    To Exchange Them For Valuable<br />
                    Roles And Unique NFTs.<br />
                </article>
            </div>
        </div>
    );
}

export default WelcomePage;
