import { queryOptions } from '@tanstack/react-query';
import { getLeaders, getFrens, getProducts, getQuests, getUserInfo, getSquads, getPosition } from './api';

export const userInfoOptions = (tgId: number) => {
  return queryOptions({
    queryKey: ['userInfo', tgId],
    queryFn: () => getUserInfo(tgId),
    staleTime: 10000,
  });
};

export const questsOptions = (telegramId: number) => {
  return queryOptions({
    queryKey: ['userQuest'],
    queryFn: () => getQuests(telegramId),
    staleTime: 1000,
  });
};

export const productsOptions = () => {
  return queryOptions({
    queryKey: ['products'],
    queryFn: () => getProducts(),
    staleTime: 1000,
  });
};

export const frensOptions = (tgId: number) => {
  return queryOptions({
    queryKey: ['frens', tgId],
    queryFn: () => getFrens(tgId),
    staleTime: 10000,
  });
};

export const leadersOptions = () => {
  return queryOptions({
    queryKey: ['leaders'],
    queryFn: () => getLeaders(),
    staleTime: 10000,
  });
};

export const squadsOptions = () => {
  return queryOptions({
    queryKey: ['squads'],
    queryFn: () => getSquads(),
    staleTime: 10000,
  });
};

export const positionOptions = (tgId: number) => {
  return queryOptions({
    queryKey: ['position'],
    queryFn: () => getPosition(tgId),
    staleTime: 10000,
  });
};