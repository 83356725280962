import "./NavMenu.css";
import { NavMenuItem } from "../data/types";

interface NavMenuProps {
  items: NavMenuItem[];
  selectedItemId: number;
  setSelectedMenuItemId: (id: number) => void;
}

function NavMenu({
  items,
  selectedItemId,
  setSelectedMenuItemId,
}: NavMenuProps) {
  return (
    <footer>
      <img src="/images/bottom-split-line.png" alt="" />
      <nav>
        <ul>
          {items.map((item) => (
            <li
              className={
                selectedItemId === item.id ? "selected-tab" : undefined
              }
              onClick={() => setSelectedMenuItemId(item.id)}
              key={item.id}
            >
              {item.icon}
            </li>
          ))}
        </ul>
      </nav>
    </footer>
  );
}

export default NavMenu;
