import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ReactComponent as ShurikenIcon } from "../../../images/shurikenIcon.svg";
import { ReactComponent as Coin } from "../../../images/fairIcon.svg";
import { userInfoOptions, productsOptions } from "../../../data/queryOptions";
import { Product } from "../../../data/types";
import { server_link } from "../../../config/config";
import "./MarketView.css";

const telegramId = window.Telegram.WebApp.initDataUnsafe?.user?.id || -1;

function MarketView() {
  const { data: userInfo } = useQuery(userInfoOptions(telegramId));
  const { data: products } = useQuery(productsOptions());
  const [coins, setCoins] = useState<number>(userInfo?.coins || 0);
  const [shuriken, setShuriken] = useState<number>(userInfo?.shuriken || 0);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(true);

  const buyProduct = async (product: Product) => {
    if (product.price <= coins) {
      const response = await fetch(`${server_link}/api/users/buy`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          telegramId,
          productId: product.id,
        }),
      });
      const data = await response.json();
      if (data.data) {
        setIsSuccessful(true);
        setCoins((prev) => prev - product.price);
        //setShuriken((prev) => prev + product.value);
      }
    } else {
      setIsSuccessful(false);
    }
    setIsPopupOpen(true);
  };

  return (
    <>
      {isPopupOpen && (
        <MarketPopup isSuccessful={isSuccessful} onClose={setIsPopupOpen} />
      )}
      <header className="vertical-center market-header">
        <h1>marketplace</h1>
        <div className="user_coins-box">
          <div className="user_coins-box-shadow" />
          <div className="user_coins-box-info">
            <div className="user_coins-item" id="red">
              <div className="fair-coin-icon" />
              {coins}
            </div>
            <div className="user_coins-item">
              <div className="shuriken-coin-icon" />
              {shuriken}
            </div>
          </div>
        </div>
      </header>

      <div className="horizontal-center header-separator">
        <img className="top" src="/images/top-split-line.png" alt="" />
      </div>
      <div className="market_cards-wrapper">
        {products?.map((item) => (
          <div
            className="market_card"
            key={`product_${item.id}`}
            onClick={() => buyProduct(item)}
          >
            <div className="product-count">+{item.value * 100}%</div>
            <div className="product-image">
              <ShurikenIcon className="product" />
            </div>
            <div className="product-price">
              {item.price === 0 ? (
                "FREE"
              ) : (
                <div className="product-price-item">
                  <div className="fair-coin-icon" />
                  {item.price}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default MarketView;

function MarketPopup({
  isSuccessful,
  onClose,
}: {
  isSuccessful: boolean;
  onClose: (value: boolean) => void;
}) {
  return (
    <div className="market-popup-wrapper" onClick={() => onClose(false)}>
      <div className="market-popup">
        <div className="market-popup-cover" />
        <div className="market-popup-notification">
          {isSuccessful ? (
            <p>Successful!</p>
          ) : (
            <p>
              Something <br />
              went wrong
            </p>
          )}
          <div className="market-popup-notification-text">
            <div className="market-popup-notification-text-cover" />
            {isSuccessful ? (
              <p>
                The item will be available in your Hattori:
                <br /> Battle Clash account within 5 minutes
              </p>
            ) : (
              <p>
                You don’t have enough
                <br />
                Hattori Points{" "}
                <span>
                  {" "}
                  (<Coin className="coin-icon" />)
                </span>
              </p>
            )}
          </div>
        </div>
        <div className="popup_close" onClick={() => onClose(false)}>
          TAP TO CLOSE
        </div>
      </div>
    </div>
  );
}
