import {
  AddCoinsRequest,
  QuestStatusRequest,
  UserRequest,
  QuestCompletedResponse,
  BalanceResponse,
  ClaimResponse,
  QuestsResponse,
  ApplyEmailRequest,
  ApplyEmailResponse,
  ProductResponse,
  UserData,
} from './types';

import { server_link } from '../config/config';

function get<T>(url: string): Promise<T> {
  return fetch(url, {})
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json() as Promise<T>;
    })
    .then((data) => {
      return data;
    });
}

function send<RequestT, ReponseT>(httpMethod: string, url: string, data?: RequestT): Promise<ReponseT> {
  return fetch(url, {
    method: httpMethod,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: data ? JSON.stringify(data) : '{}',
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json() as Promise<ReponseT>;
    })
    .then((data) => {
      return data;
    });
}

function post<RequestT, ReponseT>(url: string, data?: RequestT): Promise<ReponseT> {
  return send('POST', url, data);
}

function put<RequestT, ReponseT>(url: string, data?: RequestT): Promise<ReponseT> {
  return send('PUT', url, data);
}

export const getUserInfo = async (telegramId: number): Promise<UserData> => {
  const response = await get<any>(`${server_link}/api/users/info/${telegramId}`);
  return response.data;
};

export const getFrens = async (telegramId: number): Promise<Array<UserData>> => {
  const response = await get<any>(`${server_link}/api/users/frens/${telegramId}`);
  return response.data;
};

export const addCoins = async (telegramId: number, coins: number) => {
  const response = await post<AddCoinsRequest, BalanceResponse>(`${server_link}/api/users/coins/add`, {
    telegramId: telegramId,
    coins: coins,
  });
  return response.data;
};

export const applyEmail = async (telegramId: number, email: string) => {
  const response = await post<ApplyEmailRequest, ApplyEmailResponse>(`${server_link}/api/users/email`, {
    telegramId,
    email,
  });
  return response.data;
}

export const getQuests = async (telegramId: number) => {
  const response = await get<QuestsResponse>(`${server_link}/api/quests?telegramId=${telegramId}`);
  return response.data;
};

export const getProducts = async () => {
  const response = await get<ProductResponse>(`${server_link}/api/product`);
  return response.data;
}

export const verifyQuest = async (telegramId: number, questId: string) => {
  const response = await post<QuestStatusRequest, QuestCompletedResponse>(`${server_link}/api/quests/verify`, {
    telegramId: telegramId,
    questId: questId,
  });
  return response.data;
};

export const isQuestCompleted = async (telegramId: number, questId: string) => {
  const response = await get<QuestCompletedResponse>(`${server_link}/api/quests/${questId}/completed/${telegramId}`);
  return response.data;
};

export const claimCoins = async (telegramId: number) => {
  const response = await put<UserRequest, ClaimResponse>(`${server_link}/api/users/${telegramId}/coins/claim`);
  return response.data;
};

export enum League {
  IRON = 'IRON',
  SILVER = 'SILVER',
  PLATINUM = 'PLATINUM',
  DIAMOND = 'DIAMOND',
  GRANDMASTER = 'GRANDMASTER',
}

type LeadersOfLeague = {
  leaders: Array<{ totalBalance: number, name: string }>;
};

export type Leaders = {
  [key in League]: LeadersOfLeague;
};

export const getLeaders = async (): Promise<Leaders> => {
  const response = await get<any>(`${server_link}/api/users/leaders`);
  return response.data;
};

export type Squads = {
  [key in League]: Array<{ _id: string, league: League, position: string; totalBalance: number; name: string; count: number; topPlayers: Array<{ name: string; totalBalance: number }> }>;
};

export const getSquads = async (): Promise<Squads> => {
  const response = await get<any>(`${server_link}/api/users/squads`);
  return response.data;
}

export type Position = {
  position: string;
  league: League | null;
};

export const getPosition = async (telegramId: number): Promise<Position> => {
  const response = await get<any>(`${server_link}/api/users/position/${telegramId}`);
  return response.data;
}

export const joinSquad = async (telegramId: number, squadId: string) => {
  await post(`${server_link}/api/users/squad`, {
    telegramId,
    squadId,
  });
}