import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import MainPage from "./components/pages/MainPage";
import WelcomePage from "./components/pages/WelcomePage";
import EmailPage from "./components/pages/EmailPage";
import Game from "./components/pages/Game";
import "./App.css";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { userInfoOptions } from "./data/queryOptions";

const telegramId = window.Telegram.WebApp.initDataUnsafe?.user?.id || -1;
export const SHURIKEN_REFILL_TIMEOUT = 21600000;

function App() {
  const queryClient = useQueryClient();
  const { data: userInfo } = useQuery(userInfoOptions(telegramId));

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.ready();

    tg.expand();
    tg.setBackgroundColor("#000000");
  }, []);

  useEffect(() => {
    if (!userInfo) return;

    const int = setInterval(async () => {
      if (
        userInfo.shuriken <= 0 &&
        Date.now() - Date.parse(userInfo.shurikenExhaustedAt) >
          SHURIKEN_REFILL_TIMEOUT
      ) {
        await queryClient.invalidateQueries({
          queryKey: ["userInfo", telegramId],
        });
      }
    }, 1000);

    return () => {
      clearInterval(int);
    };
  }, [userInfo]);

  return (
    <div className="app-root">
      <Routes>
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/" element={<MainPage />} />
        <Route path="/email" element={<EmailPage />} />
        <Route path="/game" element={<Game />} />
      </Routes>
    </div>
  );
}

export default App;
