import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import QuestInfo from '../../QuestInfo';
import { QuestData } from '../../../data/types';
import { questsOptions, userInfoOptions } from '../../../data/queryOptions';
import { showPopup } from '../../Popup';
import Timer from '../../Timer';
import './QuestsView.css';


const telegramId = window.Telegram.WebApp.initDataUnsafe?.user?.id || -1;

function QuestsView() {
  const { data: quests, isPending, isError, refetch: getQuestsRefetch } = useQuery(questsOptions(telegramId));
  const { data: userInfo } = useQuery(userInfoOptions(telegramId));
  const [notCompletedQuests, setNotCompletedQuests] = useState<QuestData[]>([]);
  const [completedQuests, setCompletedQuests] = useState<QuestData[]>([]);

  const [coins] = useState<number>(userInfo?.coins || 0)
  const [dailyQuestDate, setDailyQuestDate] = useState<string | undefined>(undefined)

  const splitQuests = useCallback(async () => {
    if (!quests) {
      return;
    }

    const notСompleted: QuestData[] = [];
    const completed: QuestData[] = [];
    for (let i = 0; i < quests!.length; i++) {
      if (dailyQuestDate === undefined && quests[i].end) setDailyQuestDate(quests[i].end)
      if (quests[i].completed) {
        completed.push(quests![i]);
      } else {
        notСompleted.push(quests![i]);
      }
    }

    setNotCompletedQuests(notСompleted);
    setCompletedQuests(completed);
  }, [dailyQuestDate, quests]);

  function onQuestCompleted(id: string) {
    notCompletedQuests.forEach((item, index) => {
      if (item.id === id) {
        notCompletedQuests.splice(index, 1);
        setNotCompletedQuests([...notCompletedQuests]);

        completedQuests.push(item);
        setCompletedQuests([...completedQuests]);
      }
    });
    getQuestsRefetch();
  }

  useEffect(() => {
    if (quests) {
      splitQuests();
    }
  }, [quests, splitQuests]);

  if (isPending) {
    return <>Loading...</>;
  }

  if (isError) {
    return <>Something went wrong...</>;
  }

  return (
    <>
      <header className="vertical-center market-header">
        <h1>quests</h1>
        <div className="user_coins-box user_coins-box_quests">
          <div className="user_coins-box-shadow" />
          <div className="user_coins-box-info user_coins-box-info_quests">
            <div className="user_coins-item" id='red'>
              <div className="fair-coin-icon" />
              {coins.toLocaleString()}
            </div>
          </div>
        </div>
      </header>

      <div className="horizontal-center quests-header-separator">
        <img className="top" src="/images/top-split-line.png" alt="" />
      </div>

      <div className="view-info-holder vertical-center view-info-holder-quests">
        <article className="view-info">
          points will be credited automatically
          <br />
          after completing tasks
        </article>
      </div>

      <div className="quests-list">
        <div className='quests-list-type'>
          <p className='quests-list-type-title'>daily</p>
          {dailyQuestDate && (
            <div>
              <span className="quest-time-title">reset in: </span>
              <Timer time={new Date(dailyQuestDate)} showUnits={true} expirationMessage="EXPIRED" expireCallback={() => { }} />
            </div>
          )}
        </div>
        {notCompletedQuests.filter(quest => quest.end).map((quest) => (
          <div className="horizontal-center" key={quest.id}>
            <QuestInfo
              id={quest.id}
              link={quest.link}
              title={quest.title}
              target={quest.target}
              cost={quest.cost}
              completed={false}
              end={quest.end ? new Date(quest.end) : undefined}
              completedCallback={onQuestCompleted}
            />
          </div>
        ))}
        {completedQuests.filter(quest => quest.end).map((quest) => (
          <div className="horizontal-center" key={quest.id} onClick={() => showPopup(`Task is not completed`)}>
            <QuestInfo
              id={quest.id}
              link={quest.link}
              title={quest.title}
              target={quest.target}
              cost={quest.cost}
              completed={true}
              end={quest.end ? new Date(quest.end) : undefined}
              completedCallback={() => { }}
            />
          </div>
        ))}

        <div className='quests-list-type'>
          <p className='quests-list-type-title'>game missions</p>
        </div>
        {notCompletedQuests.filter(quest => !quest.end).map((quest) => (
          <div className="horizontal-center" key={quest.id}>
            <QuestInfo
              id={quest.id}
              link={quest.link}
              title={quest.title}
              target={quest.target}
              cost={quest.cost}
              completed={false}
              end={quest.end ? new Date(quest.end) : undefined}
              completedCallback={onQuestCompleted}
            />
          </div>
        ))}
        {completedQuests.filter(quest => !quest.end).map((quest) => (
          <div className="horizontal-center" key={quest.id}>
            <QuestInfo
              id={quest.id}
              link={quest.link}
              title={quest.title}
              target={quest.target}
              cost={quest.cost}
              completed={true}
              end={quest.end ? new Date(quest.end) : undefined}
              completedCallback={() => { }}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default QuestsView;
